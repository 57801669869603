import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/dolciariachirico/public_html/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/dolciariachirico/public_html/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/dolciariachirico/public_html/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/dolciariachirico/public_html/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/dolciariachirico/public_html/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/dolciariachirico/public_html/src/components/loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/dolciariachirico/public_html/src/lib/AntdRegistry.tsx");
