"use client"
import { LoaderService } from '@/src/services/loader';
import { LoggerService } from '@/src/services/logger';

import React, { Component } from 'react';
import './loader.css';

type MyProps = {};
type MyState = { display: string };
class LoaderComponent extends Component<MyProps, MyState> {
    constructor(props: MyProps) {
        super(props);
        this.state = {
            display: "none"
        };
    }

    componentDidMount() {
      const loaderService = LoaderService.getInstance();
      const loaderSubscription = loaderService.isLoading.subscribe(
          isLoading => {
              const displayState = isLoading ? "block" : "none"
              this.setState({display: displayState})
      })
    }

    render() {
        // @ts-ignore
        LoggerService.getLogger().debug(`<<< Display state: ${this.state.display}`)

        // @ts-ignore
        return (
          <div className="loader" style={{display: this.state.display}}  >
            <span className="spinner"></span>
          </div>
        );
    }
}

export default LoaderComponent;